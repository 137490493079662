// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgAppLogoShortMonochrome = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={98} height={88} viewBox="0 0 98 88" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M8.246 88H0l11.455-45.82a23.99 23.99 0 0 1 12.101-15.417L8.246 88ZM22.675 88h-8.243l15.402-61.608a19 19 0 0 1 11.516-13.09L22.675 88ZM37.106 88H28.86L47.8 12.246a16.167 16.167 0 0 1 31.37 0L98 88h-8.14L71.407 14.186a8.167 8.167 0 0 0-15.847 0L37.106 88Z"
      fill="#fff"
    />
    <path
      d="M69.246 88H61l-8.855-35.418 4.123-16.492L69.246 88ZM83.675 88h-8.243l-16.07-64.281 2.018-8.077a2.167 2.167 0 0 1 4.206 0L83.676 88Z"
      fill="#fff"
    />
  </svg>
))
SvgAppLogoShortMonochrome.displayName = 'SvgAppLogoShortMonochrome'
const Memo = memo(SvgAppLogoShortMonochrome)
export { Memo as SvgAppLogoShortMonochrome }
