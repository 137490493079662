// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgJoystreamLogoShort = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={26} height={32} viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_1008_6542)" fill="#4038FF">
      <path d="m16.96.016 2.922.007-.036 13.676a13.065 13.065 0 0 1-3.32 8.677c.26-1.127.393-2.28.395-3.438l.04-18.922Zm-9.397 23.85a7.823 7.823 0 0 1-5.982 2.892l.88-2.906 5.102.014Zm1.788-5.235v.291a7.793 7.793 0 0 1-.458 2.618l-5.73-.016.875-2.909 5.313.016ZM22.211.03h2.91l-.02 8.45a13.065 13.065 0 0 1-3.327 8.677c.261-1.127.394-2.28.397-3.438L22.211.03Z" />
      <path d="m14.64 0-.05 18.93A13.112 13.112 0 0 1 1.456 31.992H0l.878-2.908h.584A10.202 10.202 0 0 0 11.69 18.927L11.728 0h2.913-.001Z" />
    </g>
    <defs>
      <clipPath id="clip0_1008_6542">
        <path fill="#fff" d="M0 0h26v32H0z" />
      </clipPath>
    </defs>
  </svg>
))
SvgJoystreamLogoShort.displayName = 'SvgJoystreamLogoShort'
const Memo = memo(SvgJoystreamLogoShort)
export { Memo as SvgJoystreamLogoShort }
